<template>

	<div class="form-group ">
		<h5>{{ $t('planes.gravityCoords') }}</h5>
		<div class="row preview" v-if="!showMode">
			<div class="col-4">
				<label for="yMin">{{ $t('planes.yMin') }}</label>
				<div class="w-100 d-flex">
					<input id="yMin" class="form-control" type="number" v-model="planeToSave.gravityYMin"/>
					<plane-change-indicator field="gravityYMin" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
			</div>
			<div class="col-4">
				<label for="yMax">{{ $t('planes.yMax') }}</label>
				<div class="w-100 d-flex">
					<input id="yMax" class="form-control" type="number" v-model="planeToSave.gravityYMax"/>
					<plane-change-indicator field="gravityYMax" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
			</div>
			<div class="col-4">
				<label for="unit">{{ $t('planes.YUnit') }}</label>
				<div class="w-100 d-flex">
					<select class=" form-control "
									v-model="planeToSave.gravityYUnit"
									id="yUnit">
						<option value="kg">{{$t('unit.kg')}}</option>
						<option value="lbs">{{$t('unit.lbs')}}</option>
					</select>
					<plane-change-indicator field="gravityYUnit" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
			</div>
			<div class="col-4">
				<label for="xMin">{{ $t('planes.xMin') }}</label>
				<div class="w-100 d-flex">
					<input id="xMin" class="form-control" type="number" v-model="planeToSave.gravityXMin"/>
					<plane-change-indicator field="gravityXMin" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
			</div>
			<div class="col-4">
				<label for="xMax">{{ $t('planes.xMax') }}</label>
				<div class="w-100 d-flex">
					<input id="xMax" class="form-control" type="number" v-model="planeToSave.gravityXMax"/>
					<plane-change-indicator field="gravityXMax" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
			</div>
			<div class="col-4">
				<label for="xUnit">{{ $t('planes.XUnit') }}</label>
				<div class="w-100 d-flex">
					<select class=" form-control"
									v-model="planeToSave.gravityXUnit"
									id="xUnit">
						<option value="in">{{$t('unit.inch')}}</option>
						<option value="m">{{$t('unit.meter')}}</option>
						<option value="cm">{{$t('unit.centimeter')}}</option>
					</select>
					<plane-change-indicator field="gravityXUnit" :planeChanges="planeChanges" @resetChange="resetChange" />
				</div>
			</div>

		</div>
		<div class="d-flex flex-md-row flex-column-reverse align-items-md-start align-items-center  justify-content-center">
			<div id="cogDataContainer" style="position: relative; overflow: auto;width: calc(100% + 30px);"  class="flex-grow-1">
				<div id="cogData"></div>
			</div>
			<div class="flex-shrink-0 pl-3 d-flex flex-column align-items-center justify-content-center" style="padding-top: 16px" v-if="isEditing">
<!--				<h6 class="text-center" style="margin-top: 11px" >{{ $t('planes.cogData') }}</h6>-->
				<div class="d-flex w-100" style="gap: 0.2rem;">
					<span class="flex-1 text-center">{{$t('planes.yUnit')}}</span>
					<span class="flex-1 text-center">{{$t('planes.xUnit')}}</span>
					<span style="width: 40px"></span>
				</div>
				<div style="max-height: 520px; overflow-y: auto;display: flex; flex-direction: column; gap: 0.4rem;" >
					<div v-for="(pos, index) in planeToSave.gravityPoints" :key="index">
						<div class="d-flex" style="gap: 0.2rem;">
							<input class="form-control" type="number" v-model="pos.yValue" style="width: 80px"/>
							<input class="form-control" type="number" v-model="pos.xValue" style="width: 80px;"/>
							<button class="btn btn-danger mx-0 text-center flex-shrink-1" v-on:click="planeToSave.gravityPoints.splice(index, 1)">
								<font-awesome-icon class="mx-0" icon="minus"/>
							</button>
						</div>
					</div>
					<div class="d-flex mt-1 border-top pt-2" style="gap: 0.2rem;">
						<input
										class="form-control"
										type="number"
										v-model="gravityPointToAdd.yValue"
										style="width: 80px;"
										:class="{ 'border-danger': gravityPointErrors['new'] }"
						/>
						<input class="form-control"
										type="number"
										v-model="gravityPointToAdd.xValue"
										style="width: 80px;"
										:class="{ 'border-danger border border-4': gravityPointErrors['new'] }"
						/>
						<button class="btn btn-success mx-0 text-center flex-shrink-1" v-on:click="addGravityPoint">
							<font-awesome-icon class="mx-0" icon="plus"/>
						</button>
					</div>
				</div>

			</div>

		</div>

	</div>
</template>

<script>
import PlaneChangeIndicator from "@/components/plane/PlaneChangeIndicator.vue";
import p5 from 'p5';
export default {
	name: "CustomPlaneFormCogData",
	components: { PlaneChangeIndicator },
	data() {
		return {
			canvasSetup: false,
			cogPoints: [],
			gravityPointToAdd: {
				xValue: null,
				yValue: null,
			},
			gravityPointErrors: {},
		}
	},
	props: {
		msgs: Object,
		planeToSave: Object,
		showMode: false,
		takeOffData: null,
		landingData: null,
		planeChanges: Array,
		isEditing: Boolean,
		canWrite: {
			type: Boolean,
			default: false,
		},
		isMainEditor: Boolean,
	},
	methods: {
		resetChange(...p) {
			this.$emit('resetChange', ...p);
		},
		showGravityPointError(index, error) {
			this.gravityPointErrors[index] = error;
		},
		addGravityPoint() {
			if (!this.gravityPointToAdd.xValue || !this.gravityPointToAdd.yValue) {
				this.gravityPointErrors['new'] = this.$t('planes.gravityPointErrorEmpty');
				return;
			}

			this.gravityPointToAdd.xValue = Number(this.gravityPointToAdd.xValue);
			this.gravityPointToAdd.yValue = Number(this.gravityPointToAdd.yValue);
			if(isNaN(this.gravityPointToAdd.xValue) || isNaN(this.gravityPointToAdd.yValue)) {
				this.gravityPointErrors['new'] = this.$t('planes.gravityPointErrorNaN');
				return;
			}
			if(this.gravityPointToAdd.xValue < this.planeToSave.gravityXMin || this.gravityPointToAdd.xValue > this.planeToSave.gravityXMax) {
				this.gravityPointErrors['new'] = this.$t('planes.gravityPointErrorX', {
					min: this.planeToSave.gravityXMin,
					max: this.planeToSave.gravityXMax,
				});
				return;
			}

			this.gravityPointErrors['new'] = null;

			this.planeToSave.gravityPoints.push({
				xValue: this.gravityPointToAdd.xValue,
				yValue: this.gravityPointToAdd.yValue,
				version: 1,
			});
			this.gravityPointToAdd.xValue = null;
			this.gravityPointToAdd.yValue = null;
		},
		erase() {
			this.planeToSave.gravityPoints.pop();
		},
		eraseAll() {
			this.planeToSave.gravityPoints = [];
		},
		showCog(weight, cog, landingWeight, landingCog) {
			this.cogPoints = [
				{
					x: cog,
					y: weight
				},
				{
					x: landingCog,
					y: landingWeight,
				}
			];
		},



		buildP5() {

			const canvas = (p) => {

				// Canvas variables
				const gridCountX = 50;
				const gridCountY = 50;
				const marginLeft = 40;
				const marginBottom = 40;
				const marginTop = 40;
				const marginRight = 30;


				// Grid variables
				let minValueX = 0;
				let maxValueX = 100;
				let minValueY = 0;
				let maxValueY = 100;
				let unitX = "m";
				let unitY = "l";


				let selectedPoint = null;
				let offset = null;
				let dragging = false;
				let stepX = 1;
				let stepY = 1;
				let tickStepX = 1;
				let tickStepY = 1;
				let cogPoints = [];



				p.setup = () => {
					const canvas = p.createCanvas(document.getElementById("cogDataContainer").offsetWidth, 600);
					canvas.elt.addEventListener("contextmenu", (e) => e.preventDefault())
					p.clear();

					stepX = (p.width - marginLeft - marginRight) / gridCountX;
					stepY = (p.height - marginTop - marginBottom) / gridCountY;

					calcVars();


					drawAxesLabels()
				}

				p.draw = () => {
					if(this.canWrite){
						p.frameRate(5);
					}else{
						p.frameRate(p.mouseIsPressed ? 60 : 1);
					}


					calcVars();

					p.clear();
					drawGrid();
					drawTicks();
					drawAxes();
					drawAxesLabels();
					drawCogPoints();





					if (this.planeToSave.gravityPoints.length > 1) {
						drawLines(this.planeToSave.gravityPoints);
					}

					for (const point of this.planeToSave.gravityPoints) {
						drawPoint(getXFromValue(point.xValue), getYFromValue(point.yValue));
					}


					// let fps = p.frameRate();
					// p.fill(255);
					// p.stroke(0);
					// p.text("FPS: " + fps.toFixed(2), p.width - 50, 10);
				}


				// Mouse Events
				p.mousePressed = () => {
					if(!this.canWrite) return;
					if(!isMouseOnCanvas()) return;
					const points = this.planeToSave.gravityPoints;
					for (let i = 0; i < points.length; i++) {
						const x = getXFromValue(points[i].xValue);
						const y = getYFromValue(points[i].yValue);

						const d = p.dist(p.mouseX, p.mouseY, x, y);
						if (d < 8) {
							selectedPoint = points[i];
							offset = {
								x: x - p.mouseX,
								y: y - p.mouseY
							};
							break;
						}
					}
					if(p.mouseButton === p.RIGHT) {
						this.planeToSave.gravityPoints = this.planeToSave.gravityPoints.filter(p => p !== selectedPoint);
						selectedPoint = null;
						offset = null;
					}
				}
				p.mouseDragged = () => {
					if(!this.canWrite) return;
					if(p.mouseButton === p.RIGHT) return;
					dragging = true;
					if (!selectedPoint) return;
					p.cursor('grab')
					selectedPoint.xValue = getValueFromX(mapX(roundToGridX(p.mouseX + offset.x)));
					selectedPoint.yValue = getValueFromY(mapX(roundToGridY(p.mouseY + offset.y)));
				}
				p.mouseReleased = () => {
					if(!this.canWrite) return;
					if(p.mouseButton === p.RIGHT) return;
					p.cursor(p.ARROW)
					if (selectedPoint && dragging) {
						selectedPoint = null;
						offset = null;
						dragging = false;

						return;
					}
					dragging = false;

					if(!isMouseOnCanvas()) return;
					const snappedX = getValueFromX(mapX(roundToGridX(p.mouseX)));
					const snappedY = getValueFromY(mapX(roundToGridY(p.mouseY)));

					this.planeToSave.gravityPoints.push({ xValue: snappedX, yValue: snappedY, version: 1 });
				}


				const calcVars = () => {
					unitX = this.planeToSave.gravityXUnit;
					unitY = this.planeToSave.gravityYUnit;

					this.planeToSave.gravityXMax = Number(this.planeToSave.gravityXMax);
					this.planeToSave.gravityXMin = Number(this.planeToSave.gravityXMin);
					this.planeToSave.gravityYMax = Number(this.planeToSave.gravityYMax);
					this.planeToSave.gravityYMin = Number(this.planeToSave.gravityYMin);

					const maxValueXChanged = maxValueX !== this.planeToSave.gravityXMax;
					const maxValueYChanged = maxValueY !== this.planeToSave.gravityYMax;


					minValueX = this.planeToSave.gravityXMin;
					maxValueX = this.planeToSave.gravityXMax;
					minValueY = this.planeToSave.gravityYMin;
					maxValueY = this.planeToSave.gravityYMax;

					if(maxValueXChanged || !this.canvasSetup) {
						const maxXSize = p.textWidth(getGridLabelXValue(maxValueX)) + 1;
						tickStepX = 1;
						if (stepX < maxXSize) {
							tickStepX *= Math.round(maxXSize / stepX);
						}

						while (gridCountX % tickStepX !== 0) {
							tickStepX++;
						}
					}

					if(maxValueYChanged || !this.canvasSetup) {
						tickStepY = 1;
						if (stepY < p.textAscent() * 2) {
							tickStepY *= Math.round(p.textAscent() * 2 / stepY);
						}
						while (gridCountY % tickStepY !== 0) {
							tickStepY++;
						}
					}


					if(!this.canvasSetup) {
						this.canvasSetup = true;
					}
				}


				// Drawing functions
				const drawPoint = (x, y) => {
					p.noStroke();
					p.fill('red');
					p.ellipse(x, y, 10, 10);

					p.textAlign(p.CENTER, p.CENTER);
					p.textSize(10)
					p.textStyle(p.BOLD)

					const txt = `
            ${getGridLabelXValue(mapX(x))}
            ${getGridLabelYValue(mapY(y))}
            `;

					p.text(txt, x - (p.textSize(txt) / 2), y - p.textAscent() * 2 - 3)
					p.textStyle(p.NORMAL)


				}
				const drawCogPoints = () => {
					if(this.cogPoints.length < 2) return;
					let [{x:x1,y:y1}, {x:x2,y:y2}] = this.cogPoints;
					x1 = getXFromValue(x1);
					y1 = getYFromValue(y1);
					x2 = getXFromValue(x2);
					y2 = getYFromValue(y2);

					p.stroke("#ff6a00");
					p.fill('#278f0b');
					p.strokeWeight(3);
					p.line(x1, y1, x2, y2);

					p.noStroke();
					p.strokeWeight(2);
					p.ellipse(x1, y1, 15, 15);
					p.fill('#8F19BDFF');
					p.ellipse(x2, y2, 15, 15);
				}
				const drawLines = (points) => {
					p.stroke('blue');
					for (let i = 0; i < points.length - 1; i++) {
						p.line(
							getXFromValue(points[i].xValue), getYFromValue(points[i].yValue),
							getXFromValue(points[i + 1].xValue), getYFromValue(points[i + 1].yValue)
						);
					}
				}

				// Base Grid
				const drawGrid = () => {
					p.stroke(200); // Faint grid color
					p.strokeWeight(0.5);

					for (let x = 0; x <= gridCountX; x ++) {
						p.line(mapXR(x * stepX), marginTop, mapXR(x * stepX), p.height - marginBottom);
					}
					for (let y = 0; y <= gridCountY; y++) {
						p.line(marginLeft, mapYR(y * stepY), p.width - marginRight, mapYR(y * stepY));
					}
					p.strokeWeight(1)
				}
				const drawAxes = () => {
					p.stroke(255, 0, 0);
					p.strokeWeight(3);
					p.line(marginLeft, marginTop, marginLeft, p.height - marginBottom); // Y-axis
					p.line(marginLeft, p.height - marginBottom, p.width - marginRight, p.height - marginBottom); // X-axis
				}
				const drawTicks = () => {
					p.stroke('blue');
					p.strokeWeight(0.5);
					// X-axis ticks
					for (let x = 0; x <= gridCountX; x += tickStepX) {
						p.line(mapXR(x * stepX), p.height - marginBottom, mapXR(x * stepX), marginTop)
					}

					// Y-axis ticks
					for (let y = 0; y <= gridCountY; y += tickStepY) {
						p.line(marginLeft, mapYR(y * stepY), p.width - marginRight, mapYR(y * stepY))
					}
					p.noStroke()
				}
				const drawAxesLabels = () => {
					p.textAlign(p.CENTER, p.CENTER);
					p.textSize(8)
					p.fill(0);
					p.noStroke();

					// X-axis labels
					for (let x = 0; x <= gridCountX; x += tickStepX) {
						p.text(getGridLabelXValue(x * stepX), mapXR(x * stepX), p.height - (marginBottom / 2));
					}

					// Y-axis labels
					for (let y = 0; y <= gridCountY; y += tickStepY) {
						p.text(getGridLabelYValue(y * stepY), marginLeft / 2, mapYR(y * stepY));
					}
				}


				// Helper functions

				/**
				 * Maps a value from the full canvas X to the grid's canvas X
				 * @param {number} v
				 * @returns {number}
				 */
				const mapX = (v) => v - marginLeft;

				/**
				 * Maps a value from the full canvas Y to the grid's canvas Y
				 * @param {number} v
				 * @returns {number}
				 */
				const mapY = (v) => v - marginTop;

				/**
				 * Maps a value from the grid's canvas X to the fill canvas X
				 * @param {number} v
				 * @returns {number}
				 */
				const mapXR = (v) => v + marginLeft;

				/**
				 * Maps a value from the grid's canvas Y to the fill canvas Y
				 * @param {number} v
				 * @returns {number}
				 */
				const mapYR = (v) => v + marginTop;


				const isMouseOnCanvas = () => {
					return p.mouseX > 0 && p.mouseX < p.width && p.mouseY > 0 && p.mouseY < p.height;
				}

				/**
				 * Rounds a canvas X value to the nearest grid line
				 * @param {number} val
				 * @returns {number}
				 */
				const roundToGridX = (val) => {
					let v = Math.round((mapX(val)) / stepX) * stepX;
					return mapXR(Math.min(p.width - marginLeft - marginRight, Math.max(v, 0)));
				}

				/**
				 * Rounds a canvas Y value to the nearest grid line
				 * @param {number} val
				 * @returns {number}
				 */
				const roundToGridY = (val) => {
					let v = Math.round((mapY(val)) / stepY) * stepY;
					return mapYR(Math.min(p.height - marginTop - marginBottom, Math.max(v, 0)));
				}

				/**
				 * Converts a value from the canvas X to the actual X value
				 * @param {number} v
				 * @returns {number}
				 */
				const getValueFromX = (v) => {
					v = p.map(v, 0, p.width - marginLeft - marginRight, minValueX, maxValueX);
					return Math.round(v * 1000) / 1000
				}


				/**
				 * Converts a value from the canvas Y to the actual Y value
				 * @param {number} v
				 * @returns {number}
				 */
				const getValueFromY = (v) => {
					v = p.map(v, p.height - marginTop - marginBottom, 0, minValueY, maxValueY);
					return Math.round(v * 1000) / 1000
				}

				/**
				 * Converts a value from the actual X to the canvas X value
				 * @param {number} v
				 * @returns {number}
				 */
				const getXFromValue = (v) => {
					return mapXR(p.map(v, minValueX, maxValueX, 0, p.width - marginLeft - marginRight));
				}

				/**
				 * Converts a value from the actual Y to the canvas Y value
				 * @param {number} v
				 * @returns {number}
				 */
				const getYFromValue = (v) => {
					return mapYR(p.map(v, minValueY, maxValueY, p.height - marginTop - marginBottom, 0));
				}


				/**
				 * Returns the label for the canvas X value
				 * @param {number} x
				 * @returns {string}
				 */
				const getGridLabelXValue = (x) => {
					return `${getValueFromX(x)} ${unitX}`;
				}


				/**
				 * Returns the label for the canvas Y value
				 * @param {number} y
				 * @returns {string}
				 */
				const getGridLabelYValue = (y) => {
					return `${getValueFromY(y)} ${unitY}`;
				}


			}
			p5.disableFriendlyErrors = true
			let myp5 = new p5(canvas, "cogData");
		}
	},
	mounted() {
		console.log("mounted");
		if(!this.planeToSave.gravityPoints) return;
		this.planeToSave.gravityPoints = this.planeToSave.gravityPoints.map(p => {
			if(p.version) return p;
			return {
				xValue: Number(p.yValue),
				yValue: Number(p.xValue),
				version: 1,
			}
		});
		this.buildP5();
	},
	watch: {
		getPlaneToSave(plane) {
			this.planeToSave = plane;
		}
	},
}
</script>

<style scoped>
.preview {
	border: 1px solid #e0e0e0;
	padding: 15px;
}
</style>
