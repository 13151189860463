<template>
	<div :class="{
		'plane-change-indicator-box': true,
		'refused': refused,
	}" v-if="show">
		<div :id="id" class="plane-change-indicator" >
			<font-awesome-icon :icon="['fas', 'exclamation-circle']" size="lg" v-if="refused" />
			<font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="lg" v-else />
		</div>
		<b-popover :show.sync="showPopup" ref="popover" :target="id" placement="top" triggers="hover" :custom-class="`plane-change-indicator-popup ${refused ? 'refused' : ''}`">
			<div class="popup-text"  v-if="refused && hasComment">
				<span v-html="$t('plane.changeIndicatorPopupTextRefused')"></span>
				<div class="comment">
					{{ change.comment }}
					<div class="comment-author" v-if="change.approver">
						<avatar :user="change.approver" style="max-width: 24px;" />
						{{ change.approver.name || `@${change.approver.username}` }}
					</div>
				</div>
			</div>
			<div class="popup-text" v-html="$t('plane.changeIndicatorPopupTextRefused')" v-else-if="refused"></div>
			<div class="popup-text" v-html="$t('plane.changeIndicatorPopupText')" v-else></div>
			<div class="popup-actions">
				<button class="default-btn" @click="reset">
					{{ $t('plane.backToDefault') }}
					<font-awesome-icon :icon="['fas', 'undo']" />
				</button>
			</div>
		</b-popover>
	</div>
</template>

<script>


import Avatar from "@/components/profile/avatar/Avatar.vue";

export default {
	name: 'PlaneChangeIndicator',
	components: {Avatar},
	emits: ['resetChange'],
	props: {
		planeChanges: Array,
		field: String,
	},
	data() {
		return {
			showPopup: false,
			options: {
				placement: 'top',
			},
		}
	},
	methods: {
		reset() {
			this.showPopup = false;
			this.$emit('resetChange', this.field,  this.getOldValue());
		},
		getOldValue() {
			if(!this.change) return null;
			return JSON.parse(this.change.oldValue);
		}
	},
	computed: {
		hasComment() {
			if(!this.change) return false;
			return !!this.change.comment;
		},
		show() {
			if (!this.planeChanges) return false;
			return this.planeChanges.some(change => change.field === this.field);
		},
		change() {
			if (!this.planeChanges) return null;
			return this.planeChanges.find(change => change.field === this.field) || {};
		},
		refused() {
			if (!this.change) return false;
			return this.change.approved === false;
		},
		id() {
			return `popover-button-${this.field}`;
		},
	}
}
</script>

<style scoped>
.plane-change-indicator-box {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 0.5rem;
	color: #eebe01;
	cursor: pointer;
}


.plane-change-indicator-box.refused{
	color: #ff0000;
}


.plane-change-indicator-popup {
	background-color: white;
	border-radius: 5px;
	padding: 0.5rem;
	width: 200px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border: solid 2px #eebe01;
}
.plane-change-indicator-popup.refused {
	border: solid 2px #ff0000;
}


.popup-text {
	color: black;
	font-size: 0.8rem;
}

.popup-actions {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 0.5rem;
}

.default-btn {
	text-align: center;
	background: none;
	border: none;
	padding: 0.3rem 0.6rem;
	border-radius: 5px;
	font-size: 0.7rem;
	cursor: pointer;
}

.default-btn:hover {
	background-color: #757575;
	color: white;
}


.comment {
	font-size: 0.7rem;
	background: #f9f9f9;
	border-left: 3px solid #ccc;
	margin-top: 0.5rem;
	padding: 0.3rem 0.1rem 0.3rem 0.4rem;
}

.comment-author {
	display: flex;
	align-items: center;
	margin-top: 0.4rem;
	margin-bottom: 0.4rem;
	font-weight: bold;
	font-size: 0.6rem;
	gap: 0.3rem;
}



</style>
